#navbar {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 70px;
  background-color: var(--backgroundColor);
  top: 0;
  z-index: 2;
}

#background {
  position: absolute;
  width: 100%;
  height: inherit;
  background-color: var(--mainColor);
  top: 0;
  z-index: 1;
}

#content {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

#logo {
  height: 60px;
  margin-left: 30px;
  margin-top: 5px;
  z-index: 2;
  position: relative;
}

#navOptions {
  display: flex;
  justify-content: space-evenly;
  font-size: 25px;
  width: 50%;
  height: inherit;
  transition: 0.3s;
  z-index: 2;
}

.navOption {
  padding: 20px 30px 20px 30px;
  color: white;
  height: 30px;
  border-radius: 5px;
}

.navOption:hover {
  cursor: pointer;
}

#burgerNav {
  margin-top: 15px;
  display: none;
  position: relative;
  z-index: 2;
  background-color: var(--mainColor);
}

#burgerNav:hover {
  cursor: pointer;
}

#burgerNav div {
  width: 35px;
  height: 5px;
  background-color: white;
  margin: 6px 40px;
}

.hoverableOption {
  flex-direction: column;
  position: absolute;
  display: none;
  background-color: var(--darkerColor);
  top: 70px;
  border: 1px solid var(--mainColor);
  font-size: 18px;
  width: 200px;
  margin-left: -10px;
  border-radius: 5px;
}

.hoverableOption > a {
  padding: 10px;
  width: calc(100% - 20px);
  text-align: left;
  color: white;
}

.hoverableOption > a:hover,
#dropdown > a:hover {
  background-color: white;
  color: black;
  border-radius: 5px;
}

.displayed {
  display: flex;
}

@media screen and (min-width: 1024px) {
  .navOption:hover .hoverableOption {
    display: flex;
  }

  .navOption:hover {
    background-color: var(--darkerColor);
  }
}

@media only screen and (min-width: 1440px) {
  #content {
    width: 1440px;
  }
}

@media only screen and (max-width: 1024px) {
  #burgerNav {
    display: block;
  }

  .transformed {
    transform: translateY(calc(-100% + 1px));
  }

  #navOptions {
    top: 65px;
    flex-direction: column;
    right: 0;
    width: 50%;
    position: absolute;
    background-color: white;
    border-bottom: 1px solid var(--mainColor);
    border-left: 1px solid var(--mainColor);
    margin-top: 0;
    z-index: 0;
    border-bottom-left-radius: 20px;
  }

  .hoverableOption {
    position: relative;
    width: 100%;
    margin: 0;
    top: 10px;
    left: -1px;
    background-color: white;
    font-size: 14px;
    color: black;
  }

  .hoverableOption a {
    color: black;
  }

  .navOption {
    width: 100%;
    padding: 20px 0 20px 20px;
    transition: 0.3s;
    border-radius: 0;
    color: black;
    font-size: 14px;
  }

  #logo {
    height: 60px;
  }
}
