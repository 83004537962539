#index {
  min-height: calc(100vh - 370px);
  width: 100%;
  max-width: 1440px;
}

#slideshow {
  display: flex;
  justify-content: space-evenly;
}

#title {
  font-size: 30px;
  margin: 20px 0 0 30px;
  color: white;
}

#subTitle {
  font-size: 25px;
  margin: 0 0 70px 30px;
  color: white;
}

#topDecoration {
  position: absolute;
  top: -100px;
  left: -50%;
  width: 200%;
  height: 300px;
  background-color: var(--mainColor);
  z-index: -2;
  rotate: -5deg;
}

#secondaryTopDecoration {
  position: absolute;
  top: 200px;
  left: -50%;
  width: 200%;
  height: 30px;
  background-color: var(--lighterColor);
  z-index: -1;
  rotate: -5deg;
}

#decoContainer {
  position: absolute;
  width: 100vw;
  overflow: hidden;
  max-width: 100vw;
  height: 340px;
  left: 0;
  top: 0;
}

@media screen and (max-width: 768px) {
  #index {
    text-align: center;
  }

  #slideshow {
    display: inline-flex;
    flex-direction: column;
    margin-top: 20px;
  }

  #title,
  #subTitle {
    margin: 0;
  }

  #subTitle {
    margin-bottom: 30px;
  }
}
