@import url("./fonts.css");
:root {
  --mainColor: #587b7f;
  --lighterColor: #8fb2b6;
  --lightColor: #bfd0d1;
  --darkerColor: #395053;
  --backgroundColor: #ffffff;
  --textColor: #000000;
}

body {
  margin: 0;
  font-family: "Montserrat", "Roboto Mono", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--backgroundColor);
  color: var(--textColor);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: var(--textColor);
}

#content {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 120px;
  min-height: calc(100vh - 370px);
}
