#about {
  text-align: center;
}

#about img {
  width: 100%;
  height: 100%;
}

#title {
  font-size: 35px;
  margin-top: 50px;
}

.text {
  margin-top: 30px;
  font-size: 17px;
  width: 60%;
  display: inline-block;
  text-align: left;
}

.line {
  background-color: var(--mainColor);
  height: 2px;
  width: 20%;
  margin: 20px 0 20px 40%;
}

#about object {
  width: 70%;
  height: 100vh;
  margin: 20px 15% 0 15%;
  border-radius: 20px;
}

@media screen and (max-width: 768px) {
  #title {
    margin-top: 0;
  }

  .text {
    margin: 0;
    width: 80%;
  }

  #about object {
    width: 100%;
    margin: 20px 0 0 0;
  }
}
