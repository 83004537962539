#thumbnail {
  text-align: center;
  max-width: 270px;
  overflow: none;
  transition: 0.2s;
  padding: 10px;
}

#title {
  font-size: 23px;
  margin: 10px 0;
}

#text {
  text-align: left;
}

#thumbnail img {
  height: 70px;
  margin-top: 25px;
  transition: 0.2s;
}

#thumbnail .circle {
  transition: 0.2s;
}

#linkContainer {
  text-align: right;
  font-size: 18px;
  margin-top: 15px;
}

#thumbnail:hover img,
#thumbnail:hover .circle {
  transform: scale(1.15);
}

@media screen and (max-width: 768px) {
  #thumbnail {
    margin: 0 0 50px 15%;
    max-width: 70%;
  }
}
