#footer {
  width: 100vw;
  height: 150px;
  background-color: var(--mainColor);
  margin-top: 100px;
  text-align: center;
  color: white;
}

#footer_content {
  position: relative;
  height: 50%;
  top: 45%;
}
