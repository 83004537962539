#about {
  text-align: center;
}

#title {
  font-size: 35px;
  margin-top: 50px;
}

.text {
  margin-top: 30px;
  font-size: 17px;
  width: 60%;
  display: inline-block;
  text-align: left;
}

.line {
  background-color: var(--mainColor);
  height: 2px;
  width: 20%;
  margin: 20px 0 20px 40%;
}

.text a {
  color: #0000ee;
}

#machine_image {
  width: 400px;
  border-radius: 10px;
}

#image_container {
  float: left;
  min-height: 250px;
  width: 400px;
  padding: 10px;
  margin-right: 20px;
  text-align: center;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  #title {
    margin-top: 0;
  }

  .text {
    margin: 0;
    width: 80%;
  }

  #image_container,
  #machine_image {
    width: 100%;
  }

  #image_container {
    min-height: 0;
    margin-bottom: 20px;
  }
}
