#content {
  display: flex;
  margin-top: 100px;
}

#contact_info {
  padding: 30px;
  box-shadow: 0 0 3px var(--mainColor);
  height: 150px;
}

#form {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  font-size: 30px;
}

#form input {
  margin: 10px 10px;
  padding: 10px;
  border: 1px solid var(--mainColor);
  border-radius: 5px;
  width: 300px;
}

#form textarea {
  margin: 10px 10px;
  padding: 10px;
  border: 1px solid var(--mainColor);
  border-radius: 5px;
  width: 640px;
  height: 100px;
}

#send_button {
  float: right;
}

#verification {
  font-size: 20px;
  margin: 10px 10px;
  padding: 10px;
  background-color: rgba(143, 255, 151, 0.234);
  border-radius: 5px;
  width: 200px;
  text-align: center;
  font-size: 15px;
  display: none;
}

@media only screen and (max-width: 1024px) {
  #content {
    flex-direction: column;
    width: 100%;
    margin-top: 0;
  }

  #contact_info {
    margin: 0 0 0 2.5%;
    padding: 0 0 20px 0;
    text-align: center;
    height: auto;
    width: 95%;
  }

  #form {
    margin: 0 0 0 2.5%;
    width: 95%;
  }

  #form input {
    padding: 10px 0;
    margin: 10px 0;
    width: 100%;
  }

  #form textarea {
    padding: 10px 0;
    margin: 10px 0;
    width: 100%;
  }
}
